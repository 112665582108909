import PropTypes from 'prop-types';
import router from 'next/router';
import { useState, useRef, useMemo, useCallback } from 'react';
import { gql, useQuery } from '@apollo/client';
import { motion } from 'framer-motion';
import { faChevronLeft, faTimes } from '@fortawesome/pro-regular-svg-icons';
import { Image } from 'cloudinary-react';

import config from 'config';
import { useCampaignPage } from 'context/CampaignPage';
import useScreen from 'hooks/useScreen';
import Modal from 'components/common/Modal';
import ModalPage from 'components/common/ModalPage';
import Navbar from 'components/common/Navbar';
import NavbarIconButton from 'components/common/NavbarIconButton';
import NavbarTitle from 'components/common/NavbarTitle';
import JoinStoryModal from '../campaign-post-join/CampaignPostJoinStoryModal';
import InviteDonorsModal from '../campaign-post-join/CampaignPostJoinInviteDonorsModal';
import RegistrationSuccessModal from '../campaign-post-join/CampaignPostJoinRegistrationSuccessModal';
import CommunityModal from '../campaign-post-join/CampaignPostJoinCommunityModal';
import FundraiserAdminWelcomeAvatar from './FundraiserAdminWelcomeAvatar';
import FundraiserAdminWelcomeGoals from './FundraiserAdminWelcomeGoals';

const GET_FUNDRAISER_DETAILS = gql`
  query GetFundraiserDetails($id: String!) {
    findFundraisers(id: $id) {
      id
      avatarImage
      campaign {
        id
        campaignLogo
        fundraiserDefaultHeaderAvatarImage
        ownerOrganization {
          id
          logo
          name
        }

        supportedFeatures {
          join
          event
        }
      }
      monetaryGoal
      performanceEstimate
      unlockedActivityMetricId
    }
  }
`;

const FundraiserAdminWelcome = ({ type, onHide }) => {
  const { fundraiserId, setIsInviting } = useCampaignPage();
  const screen = useScreen();

  const views = useRef([]);
  const [view, setView] = useState(null);

  const { data } = useQuery(GET_FUNDRAISER_DETAILS, {
    variables: { id: fundraiserId },
    onCompleted: ({ findFundraisers }) => {
      // We only want this to run once
      if (view) return;

      const fundraiser = findFundraisers[0];
      const isEvent = fundraiser.campaign.supportedFeatures.event;
      views.current = [
        { name: 'registrationSuccessful', if: () => true },
        { name: 'goals', if: () => type === 'claimed' },
        { name: 'avatar', if: () => isEvent && !fundraiser.avatarImage },
        { name: 'story', if: () => true },
        { name: 'inviteDonors', if: () => true },
        { name: 'community', if: () => fundraiser.campaign.supportedFeatures.join },
      ].reduce((arr, item) => (item.if() ? [...arr, item.name] : arr), []);

      setView(views.current[0]);
    },
  });
  const fundraiser = useMemo(() => data?.findFundraisers[0], [data]);
  const campaign = useMemo(() => fundraiser?.campaign, [fundraiser]);
  const organization = useMemo(() => campaign?.ownerOrganization, [campaign]);
  const logo = campaign?.campaignLogo ?? organization?.logo;

  const canJoin = useMemo(() => campaign?.supportedFeatures.join, [campaign]);

  const currentViewIndex = views.current.indexOf(view);

  const goBackward = useCallback(() => {
    const prevView = views.current[currentViewIndex - 1];
    return prevView ? setView(prevView) : onHide();
  }, [currentViewIndex, onHide]);

  const goForward = useCallback(() => {
    const nextView = views.current[currentViewIndex + 1];
    return nextView ? setView(nextView) : onHide();
  }, [currentViewIndex, onHide]);

  const renderCurrentView = useCallback(() => {
    switch (view) {
      case 'registrationSuccessful': {
        return (
          <RegistrationSuccessModal
            progressCurrent={currentViewIndex + 1}
            progressTotal={views.current.length}
            onContinue={goForward}
          />
        );
      }

      case 'goals': {
        return (
          <FundraiserAdminWelcomeGoals
            progressCurrent={currentViewIndex + 1}
            progressTotal={views.current.length}
            onContinue={goForward}
          />
        );
      }

      case 'avatar': {
        return (
          <FundraiserAdminWelcomeAvatar
            progressCurrent={currentViewIndex + 1}
            progressTotal={views.current.length}
            onContinue={goForward}
          />
        );
      }

      case 'story': {
        return (
          <JoinStoryModal
            entityId={fundraiserId}
            progressCurrent={currentViewIndex + 1}
            progressTotal={views.current.length}
            onContinue={goForward}
          />
        );
      }

      case 'inviteDonors': {
        return (
          <InviteDonorsModal
            progressCurrent={currentViewIndex + 1}
            progressTotal={views.current.length}
            onContinue={() => {
              setIsInviting(true);
              goForward();
            }}
            onSkip={canJoin ? goForward : onHide}
          />
        );
      }

      case 'community': {
        return (
          <CommunityModal
            progressCurrent={currentViewIndex + 1}
            progressTotal={views.current.length}
            onContinue={() => router.push(`/manage/f/${fundraiserId}/community`)}
          />
        );
      }

      default: {
        if (config('/debug')) throw new Error(`Unknown view "${view}"`);
        return null;
      }
    }
  }, [view, currentViewIndex, goForward, canJoin, onHide, setIsInviting, fundraiserId]);

  if (!view) return null;

  if (!screen.lg) {
    return (
      <ModalPage onHide={onHide} show>
        <Navbar
          className="h-18 border-b border-gray-400 shadow-sm"
          left={
            <NavbarIconButton
              as="button"
              type="button"
              icon={currentViewIndex > 0 ? faChevronLeft : faTimes}
              onClick={goBackward}
              border
            />
          }
          center={
            logo ? (
              <Image
                publicId={logo}
                alt=""
                width={260}
                height={120}
                crop="limit"
                quality="auto"
                fetchFormat="auto"
                className="block h-10"
              />
            ) : (
              <NavbarTitle>{organization.name}</NavbarTitle>
            )
          }
        />

        <motion.div
          key={view}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          className="relative px-6 py-8"
        >
          <div className="relative z-0">{renderCurrentView()}</div>
        </motion.div>
      </ModalPage>
    );
  }

  return (
    <Modal className="md:max-w-md p-8" onHide={onHide} show>
      <motion.div key={view} initial={{ opacity: 0 }} animate={{ opacity: 1 }} className="relative">
        <NavbarIconButton
          as="button"
          type="button"
          icon={currentViewIndex > 0 ? faChevronLeft : faTimes}
          onClick={goBackward}
          border
          className="absolute left-0 top-0 z-10"
        />
        <div className="relative z-0">{renderCurrentView()}</div>
      </motion.div>
    </Modal>
  );
};

FundraiserAdminWelcome.propTypes = {
  type: PropTypes.oneOf(['buyer', 'claimed']).isRequired,
  onHide: PropTypes.func.isRequired,
};

FundraiserAdminWelcome.defaultProps = {};

export default FundraiserAdminWelcome;
