import PropTypes from 'prop-types';
import { forwardRef } from 'react';

import Button from 'components/common/Button';
import FormImageUploader from './FormImageUploader';

const FormImageUploaderButton = forwardRef(
  ({ value, buttonColor, buttonRadius, uploadLabel, continueLabel, onContinue, ...props }, ref) =>
    value ? (
      <Button
        ref={ref}
        as="button"
        type="button"
        color={buttonColor}
        radius={buttonRadius}
        className="font-medium w-full"
        onClick={onContinue}
      >
        {continueLabel}
      </Button>
    ) : (
      <FormImageUploader
        ref={ref}
        value={value}
        preview={() => (
          <Button
            as="span"
            color={buttonColor}
            radius={buttonRadius}
            className="font-medium w-full"
          >
            {uploadLabel}
          </Button>
        )}
        className="w-full"
        showEditIndicator={false}
        aspectRatio={1}
        allowCrop
        {...props}
      />
    )
);

FormImageUploaderButton.propTypes = {
  value: PropTypes.string,
  buttonColor: PropTypes.string,
  buttonRadius: PropTypes.string,
  uploadLabel: PropTypes.node,
  continueLabel: PropTypes.node,
  onContinue: PropTypes.func.isRequired,
};

FormImageUploaderButton.defaultProps = {
  value: undefined,
  buttonRadius: undefined,
  buttonColor: 'primary',
  uploadLabel: 'Upload image',
  continueLabel: 'Continue',
};

export default FormImageUploaderButton;
