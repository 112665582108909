import { useCallback, forwardRef } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { motion } from 'framer-motion';

const FormSwitch = forwardRef(({ name, value, onChange, readOnly, disabled, ...props }, ref) => {
  const handleChange = useCallback(() => onChange(!value), [value, onChange]);

  return (
    <div className="flex-inline items-center h-5">
      {/*
        Adding a hidden checkbox here allows us to control the value using external
        <label /> nodes, just like a normal checkbox would
      */}
      <input
        type="checkbox"
        className="hidden"
        name={name}
        id={name}
        onChange={handleChange}
        readOnly={readOnly}
        disabled={disabled}
        ref={ref}
        {...props}
      />
      <button
        type="button"
        style={{ padding: '2px' }}
        className={cx('inline-block w-10 rounded-full', {
          'pointer-events-none': readOnly || disabled,
          'opacity-50': disabled,
          'justify-end bg-green-500': value,
          'justify-start bg-gray-400': !value,
        })}
        onClick={handleChange}
      >
        <motion.span
          initial={false}
          animate={{ x: value ? 16 : 0 }}
          transition={{ duration: 0.05 }}
          className={cx('block w-5 h-5 rounded-full bg-white transition-all duration-150', {
            'shadow-sm': value,
          })}
        />
      </button>
    </div>
  );
});

FormSwitch.propTypes = {
  value: PropTypes.bool,
  name: PropTypes.string,
  onChange: PropTypes.func,
  readOnly: PropTypes.bool,
  disabled: PropTypes.bool,
};

FormSwitch.defaultProps = {
  value: false,
  name: undefined,
  onChange: () => {},
  readOnly: false,
  disabled: false,
};

export default FormSwitch;
