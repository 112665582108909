import PropTypes from 'prop-types';
import { useMemo, useCallback, useEffect } from 'react';
import { gql, useQuery, useMutation } from '@apollo/client';
import { useForm, Controller } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import { yupResolver } from '@hookform/resolvers/yup';
import capitalize from 'lodash/capitalize';
import * as yup from 'yup';

import config from 'config';
import { useGlobal } from 'context/Global';
import { useCampaignPage } from 'context/CampaignPage';
import { formatDateRange, formatCurrency } from 'lib/formatters';
import { makeRequired, number, currency } from 'lib/validators';
import Button from 'components/common/Button';
import ProgressIndicator from 'components/common/ProgressIndicator';
import FormFieldGroup from 'components/form/FormFieldGroup';
import FormNode from 'components/form/FormNode';
import FormLabel from 'components/form/FormLabel';
import FormNote from 'components/form/FormNote';
import FormCurrencyInput from 'components/form/FormCurrencyInput';
import FormNumberInput from 'components/form/FormNumberInput';
import FormSelect from 'components/form/FormSelect';

const FUNDRAISER_GOALS_FIELDS = gql`
  fragment FundraiserGoalsFields on Fundraiser {
    id
    monetaryGoal
    commitmentAmount
    performanceEstimate
    unlockedActivityMetricId
  }
`;

const GET_FUNDRAISER_GOALS = gql`
  ${FUNDRAISER_GOALS_FIELDS}
  query GetFundraiserGoals($id: String!) {
    findFundraisers(id: $id) {
      ...FundraiserGoalsFields
      campaign {
        id
        activeFeatures {
          activityTracking
        }
        assignedActivityMetrics {
          id
          plural
          category
        }
        event {
          id
          startDate
          endDate
        }
        fundraiserDefaultMonetaryGoal
        isMetricLabelLocked
      }
    }
  }
`;

const UPDATE_FUNDRAISER_GOALS = gql`
  ${FUNDRAISER_GOALS_FIELDS}
  mutation UpdateFundraiserGoals($data: FundraiserInput!) {
    updateFundraiser(Fundraiser: $data) {
      ...FundraiserGoalsFields
    }
  }
`;

const validationSchema = yup.object({
  monetaryGoal: currency.when(['$commitmentAmount'], ([commitmentAmount], schema) => {
    if (!commitmentAmount) return makeRequired(schema);
    return makeRequired(
      schema.test(
        'commitmentMinimum',
        `Your fundraising goal must be equal to or greater than your commitment of ${formatCurrency(
          commitmentAmount
        )}`,
        (val) => val >= +commitmentAmount
      )
    );
  }),
  performanceEstimate: number
    .integer()
    .positive(config('/validationMessages/positiveNumber'))
    .when(['$trackActivity'], {
      is: true,
      then: (schema) => makeRequired(schema),
      otherwise: (schema) => schema.strip(),
    }),
  unlockedActivityMetricId: yup.string().when(['$metricIsLocked'], {
    is: false,
    then: (schema) => makeRequired(schema),
    otherwise: (schema) => schema.strip(),
  }),
});

const FundraiserAdminWelcomeGoals = ({ progressCurrent, progressTotal, onContinue }) => {
  const { addToast } = useGlobal();
  const { fundraiserId } = useCampaignPage();

  const [updateFundraiser] = useMutation(UPDATE_FUNDRAISER_GOALS);
  const { data } = useQuery(GET_FUNDRAISER_GOALS, { variables: { id: fundraiserId } });
  const fundraiser = useMemo(() => data?.findFundraisers[0], [data]);
  const campaign = useMemo(() => fundraiser?.campaign, [fundraiser]);
  const metrics = useMemo(() => campaign?.assignedActivityMetrics ?? [], [campaign]);
  const metricIsLocked = useMemo(
    () => campaign?.isMetricLabelLocked || metrics.length === 1,
    [campaign, metrics]
  );
  const metricOptions = useMemo(
    () => metrics.map((metric) => ({ label: capitalize(metric.plural), value: metric.id })),
    [metrics]
  );

  const { reset, formState, watch, handleSubmit, control } = useForm({
    context: {
      metricIsLocked,
      trackActivity: campaign?.activeFeatures.activityTracking,
      commitmentAmount: fundraiser?.commitmentAmount,
    },
    resolver: yupResolver(validationSchema),
  });
  const { isDirty, errors, isSubmitting } = formState;
  const unlockedActivityMetricId = watch('unlockedActivityMetricId');

  const metricLabel = useMemo(
    () =>
      (metricIsLocked ? metrics[0] : metrics.find((x) => x.id === unlockedActivityMetricId))
        ?.plural,
    [metricIsLocked, metrics, unlockedActivityMetricId]
  );
  const performanceDateRange = formatDateRange(campaign?.event.startDate, campaign?.event.endDate);

  const onSubmit = useCallback(
    async (values) => {
      try {
        if (isDirty) {
          await updateFundraiser({
            variables: {
              data: { id: fundraiserId, ...values },
            },
          });
        }

        onContinue();
      } catch (err) {
        if (config('/debug')) console.error(err);
        addToast({
          id: 'fundraiserGoals',
          type: 'error',
          duration: 'long',
          message: 'There was an error saving your changes, please try again later',
        });
      }
    },
    [isDirty, updateFundraiser, fundraiserId, onContinue, addToast]
  );

  useEffect(
    () =>
      reset({
        monetaryGoal: fundraiser?.monetaryGoal ?? campaign?.fundraiserDefaultMonetaryGoal ?? '',
        ...(campaign?.activeFeatures.activityTracking && {
          performanceEstimate: fundraiser?.performanceEstimate ?? '',
          ...(!campaign?.isMetricLabelLocked && {
            unlockedActivityMetricId: fundraiser?.unlockedActivityMetricId ?? metrics[0]?.id,
          }),
        }),
      }),
    [reset, fundraiser, campaign, metrics]
  );

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <h1 className="lg:text-center pt-0.5 text-gray-800 font-medium text-2xl mb-8">
        Let&apos;s set some goals
      </h1>

      <FormFieldGroup>
        <FormNode>
          <FormLabel htmlFor="monetaryGoal" isRequired>
            Fundraising goal
          </FormLabel>
          <Controller
            control={control}
            name="monetaryGoal"
            render={({ field, fieldState }) => (
              <FormCurrencyInput status={fieldState.error ? 'error' : 'default'} {...field} />
            )}
          />
          <ErrorMessage
            errors={errors}
            name="monetaryGoal"
            render={({ message }) => <FormNote status="error">{message}</FormNote>}
          />
        </FormNode>

        {campaign?.activeFeatures.activityTracking && (
          <FormFieldGroup>
            {!metricIsLocked && (
              <FormNode>
                <FormLabel
                  id="unlockedActivityMetricIdLabel"
                  htmlFor="unlockedActivityMetricId"
                  isRequired
                >
                  What activity will you track?
                </FormLabel>
                <Controller
                  control={control}
                  name="unlockedActivityMetricId"
                  render={({ field, fieldState }) => (
                    <FormSelect
                      isClearable={false}
                      isSearchable={false}
                      options={metricOptions}
                      status={fieldState.error ? 'error' : 'default'}
                      aria-labelledby="unlockedActivityMetricIdLabel"
                      {...field}
                    />
                  )}
                />
                <ErrorMessage
                  errors={errors}
                  name="unlockedActivityMetricId"
                  render={({ message }) => <FormNote status="error">{message}</FormNote>}
                />
              </FormNode>
            )}
            <FormNode>
              <FormLabel htmlFor="performanceEstimate" isRequired>
                Your <strong className="font-medium">{metricLabel}</strong> goal
              </FormLabel>
              <Controller
                control={control}
                name="performanceEstimate"
                render={({ field, fieldState }) => (
                  <FormNumberInput status={fieldState.error ? 'error' : 'default'} {...field} />
                )}
              />
              <FormNote>
                Estimated {metricLabel} you expect to record during the event (
                {performanceDateRange})
              </FormNote>
              <ErrorMessage
                errors={errors}
                name="performanceEstimate"
                render={({ message }) => <FormNote status="error">{message}</FormNote>}
              />
            </FormNode>
          </FormFieldGroup>
        )}
      </FormFieldGroup>

      <div className="mt-8 flex justify-center">
        <ProgressIndicator current={progressCurrent} total={progressTotal} />
      </div>

      <div className="mt-8 grid grid-cols-1 gap-y-2">
        <Button
          as="button"
          type="submit"
          color="primary"
          radius="full"
          className="font-medium w-full"
          disabled={isSubmitting}
        >
          Continue
        </Button>
      </div>
    </form>
  );
};

FundraiserAdminWelcomeGoals.propTypes = {
  progressCurrent: PropTypes.number.isRequired,
  progressTotal: PropTypes.number.isRequired,
  onContinue: PropTypes.func.isRequired,
};

FundraiserAdminWelcomeGoals.defaultProps = {};

export default FundraiserAdminWelcomeGoals;
