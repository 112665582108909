import PropTypes from 'prop-types';
import { forwardRef } from 'react';
import { Image } from 'cloudinary-react';

import Avatar from 'components/common/Avatar';
import FormImageUploader from './FormImageUploader';

const FormImageUploaderProfilePhoto = forwardRef(({ defaultImage, value, ...props }, ref) => (
  <FormImageUploader
    ref={ref}
    value={value}
    preview={({ image }) =>
      value ? (
        <Image
          publicId={image}
          alt=""
          transformation="avatar"
          className="block w-32 rounded-full border-4 border-white shadow-lg"
        />
      ) : (
        <div className="relative">
          <div className="absolute" style={{ top: '15.5%', left: '21.5%', width: '12.5%' }}>
            <Avatar size={null} image={defaultImage} className="w-full animate-pulse" />
          </div>
          <Image
            publicId="v1604680062/mako/application/profile-photo-preview"
            className="block w-full"
          />
        </div>
      )
    }
    showEditIndicator={!!value}
    aspectRatio={1}
    allowCrop
    {...props}
  />
));

FormImageUploaderProfilePhoto.propTypes = {
  value: PropTypes.string,
  defaultImage: PropTypes.string.isRequired,
};

FormImageUploaderProfilePhoto.defaultProps = {
  value: undefined,
};

export default FormImageUploaderProfilePhoto;
