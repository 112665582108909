import { ElementType } from 'react';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowCircleRight, faChevronRight } from '@fortawesome/pro-solid-svg-icons';
import { faLock } from '@fortawesome/pro-regular-svg-icons';
import cx from 'classnames';

import useScreen from 'hooks/useScreen';
import Link from 'components/common/Link';

type AdminNavItemProps<C extends ElementType> = PolymorphicComponentProp<
  C,
  {
    label: NonEmptyReactNode;
    icon?: IconProp;
    isActive?: boolean;
    isDisabled?: boolean;
    withArrow?: boolean;
    theme?: 'light' | 'dark';
  }
>;

const AdminNavItem = <C extends ElementType = typeof Link>({
  as,
  label,
  icon = null,
  isActive = false,
  isDisabled = false,
  withArrow = false,
  theme = 'light',
  ...props
}: AdminNavItemProps<C>) => {
  const Component = as || Link;
  const screen = useScreen();

  return (
    <div className={cx('lg:-mx-4', { 'pointer-events-none opacity-20': isDisabled })}>
      <Component
        {...props}
        className={cx(
          'relative flex justify-between items-center w-full rounded-md py-3 px-6 lg:px-4 text-base sm:text-sm font-medium transition-colors duration-200',
          {
            group: !isActive,
            'text-gray-700 hover:bg-gray-300': !isActive && theme === 'light',
            'text-gray-800 bg-white shadow-sm': isActive && theme === 'light',
            'text-white bg-gray-900 hover:bg-gray-900': isActive && theme === 'dark',
            'text-gray-400 hover:bg-gray-900/50': !isActive && theme === 'dark',
          }
        )}
      >
        <span
          className={cx('transition-opacity absolute inset-y-3 left-0 w-1 bg-green-500 rounded', {
            'opacity-0': !isActive,
            'opacity-100': isActive,
          })}
          style={{ transform: 'translateX(-50%)' }}
        />
        <div className="flex items-center">
          {icon && (
            <FontAwesomeIcon
              icon={isDisabled ? faLock : icon}
              size="lg"
              className="mr-4"
              fixedWidth
            />
          )}
          {label}
        </div>
        {withArrow && (
          <FontAwesomeIcon
            icon={screen.lg ? faArrowCircleRight : faChevronRight}
            size={screen.lg ? 'lg' : '1x'}
            className="lg:block transition-opacity duration-200 opacity-25 lg:opacity-0 group-hover:opacity-100"
          />
        )}
      </Component>
    </div>
  );
};

export default AdminNavItem;
