import { useState, useMemo } from 'react';
import { useRouter } from 'next/router';
import { useQuery, gql } from '@apollo/client';
import { useIsomorphicEffect } from 'rooks';

import redirect from 'lib/redirect';
import { useAuth, GET_USER } from 'context/Auth';
import { useCampaignPage } from 'context/CampaignPage';
import useBreakpoint from 'hooks/useBreakpoint';
import CampaignPageLayout from 'components/layouts/CampaignPageLayout';
import CampaignPageSeo from 'components/campaign-page/CampaignPageSeo';
import CampaignPageFundraiserHero from 'components/campaign-page/CampaignPageFundraiserHero';
import CampaignPageFundraiserAvatar from 'components/campaign-page/CampaignPageFundraiserAvatar';
import CampaignPageFundraiserStory from 'components/campaign-page/CampaignPageFundraiserStory';
import CampaignPageFundraiserProgress from 'components/campaign-page/CampaignPageFundraiserProgress';
import CampaignPageIndividualInfo from 'components/campaign-page/CampaignPageIndividualInfo';
import CampaignPageFundraiserContributions from 'components/campaign-page/CampaignPageFundraiserContributions';
import CampaignPageUpdates from 'components/campaign-page/CampaignPageUpdates';
import CampaignPageCustomBlock from 'components/campaign-page/CampaignPageCustomBlock';
import CampaignPageLogoGrid from 'components/campaign-page/CampaignPageLogoGrid';
import AdminFundraiserEditModal from 'components/admin/AdminFundraiserEditModal';
import FundraiserAdminWelcome from 'components/fundraiser-admin/FundraiserAdminWelcome';

const GET_FUNDRAISER = gql`
  query GetFundraiser($id: String!) {
    findFundraisers(id: $id) {
      id
      userId
      myPermissions

      campaign {
        id
        fundraiserWidgets(order: "order") {
          id
          type
          title
          order
          config
        }
      }
    }
  }
`;

const FundraiserPage = () => {
  const router = useRouter();
  const mobile = !useBreakpoint('lg');
  const { user } = useAuth();
  const [showWelcome, setShowWelcome] = useState(null);

  const { fundraiserId, isEditing, setIsEditing, setIsInviting } = useCampaignPage();

  useIsomorphicEffect(() => {
    if (router.query.welcome) {
      setShowWelcome(router.query.welcome);
      router.replace(`/f/${fundraiserId}`, `/f/${fundraiserId}`, { shallow: true });
      return;
    }

    if (router.query.created) {
      setShowWelcome('classic');
      router.replace(`/f/${fundraiserId}`, `/f/${fundraiserId}`, { shallow: true });
      return;
    }

    if (router.query.action === 'reminders') setIsEditing(true);
    if (router.query.action === 'invite') setIsInviting(true);
  }, []);

  const { data } = useQuery(GET_FUNDRAISER, { variables: { id: fundraiserId } });
  const fundraiser = useMemo(() => data?.findFundraisers[0], [data]);
  const campaign = useMemo(() => fundraiser?.campaign, [fundraiser]);
  const footerSections = campaign?.fundraiserWidgets ?? [];

  const isFundraiserManager = fundraiser?.myPermissions.includes('manageFundraiser');
  const isFundraiser = fundraiser?.userId === user?.id;

  if (!fundraiser) return null;

  return (
    <>
      <CampaignPageSeo />

      {isFundraiserManager && showWelcome ? (
        <FundraiserAdminWelcome type={showWelcome} onHide={() => setShowWelcome(null)} />
      ) : null}

      {isEditing && (isFundraiserManager || isFundraiser) ? (
        <AdminFundraiserEditModal
          id={fundraiserId}
          onHide={() => setIsEditing(false)}
          initialView="goals"
        />
      ) : null}

      {mobile ? (
        <>
          <div className="bg-white border-b border-gray-300 pt-4">
            <div className="container">
              <div className="rounded-2xl overflow-hidden pb-8 -mx-2">
                <CampaignPageFundraiserHero />
                <div className="px-2">
                  <CampaignPageFundraiserProgress />
                  <CampaignPageIndividualInfo className="mt-8" />
                </div>
              </div>
            </div>
          </div>
          <div className="rounded-t-2xl bg-white border border-gray-300 mt-20 pt-16 pb-8 relative">
            <CampaignPageFundraiserAvatar className="absolute w-20 h-20 left-1/2 top-0 -translate-1/2" />
            <div className="container">
              <CampaignPageFundraiserStory canEdit={isFundraiser} />
            </div>
          </div>
          <CampaignPageUpdates className="my-4" />
          <div className="container my-12 relative z-0">
            <CampaignPageFundraiserContributions />
          </div>
        </>
      ) : (
        <div className="container mt-8 mb-12">
          <div className="flex -ml-8">
            <div className="pl-8 w-3/5">
              <div className="rounded-2xl bg-white border border-gray-300 overflow-hidden">
                <CampaignPageFundraiserHero />
                <CampaignPageFundraiserStory className="py-8 px-16" canEdit={isFundraiser} />
              </div>
              <CampaignPageUpdates className="my-8" />
              <CampaignPageFundraiserContributions className="my-16" />
            </div>
            <div className="pl-8 w-2/5">
              <div className="rounded-2xl bg-white border border-gray-300 overflow-hidden px-12 py-8">
                <CampaignPageFundraiserProgress />
              </div>
              <div className="rounded-2xl bg-white border border-gray-300 overflow-hidden px-12 py-8 mt-4">
                <CampaignPageIndividualInfo />
              </div>
            </div>
          </div>
        </div>
      )}
      {footerSections.map((section) => {
        switch (section.type) {
          case 'customBlock': {
            return <CampaignPageCustomBlock key={section.id} id={section.id} />;
          }
          case 'logoGrid': {
            return <CampaignPageLogoGrid key={section.id} id={section.id} />;
          }
          default:
            return null;
        }
      })}
    </>
  );
};

FundraiserPage.getLayout = (page, router) => (
  <CampaignPageLayout idType="fundraiser" id={router.query.id} enableCustomTheme>
    {page}
  </CampaignPageLayout>
);

FundraiserPage.getInitialProps = async ({ apolloClient, query, asPath, res }) => {
  const slugQuery = await apolloClient.query({
    query: gql`
      query GetFundraiserSlug($id: String!) {
        findFundraisers(id: $id) {
          id
          slug
          campaign {
            id
            slug
          }
        }
      }
    `,
    variables: { id: query.id },
  });

  const fundraiser = slugQuery.data.findFundraisers[0];
  if (!fundraiser) {
    res.statusCode = 404;
    return {};
  }

  // query.fundraiserSlug will already be set if rendering from the slug route
  const queryString = Object.entries(query).reduce((tempQS, [key, value]) => {
    // Don't include the ID in the redirect querystring
    if (key === 'id') return tempQS;
    const separator = tempQS ? '&' : '?';
    return (tempQS += `${separator}${key}=${value}`);
  }, '');
  if (!query.fundraiserSlug && fundraiser.slug) {
    const root = fundraiser.campaign.slug
      ? `/${fundraiser.campaign.slug}`
      : `/c/${fundraiser.campaign.id}`;
    redirect(res, `${root}/@${fundraiser.slug}${queryString}`);
    return {};
  }

  const isEditingReminders = query.action === 'reminders';
  if (!isEditingReminders) {
    return {};
  }

  const response = await apolloClient.query({
    query: GET_USER,
  });

  const user = response?.data?.me;

  if (!user) {
    redirect(res, `/login?redirectUrl=${asPath}`);
  }

  return {};
};

export default FundraiserPage;
