import PropTypes from 'prop-types';
import { useMemo } from 'react';
import { gql, useQuery, useMutation } from '@apollo/client';

import config from 'config';
import { useGlobal } from 'context/Global';
import { useAuth } from 'context/Auth';
import { useCampaignPage } from 'context/CampaignPage';
import Button from 'components/common/Button';
import ProgressIndicator from 'components/common/ProgressIndicator';
import FormImageUploaderButton from 'components/form/FormImageUploaderButton';
import FormImageUploaderProfilePhoto from 'components/form/FormImageUploaderProfilePhoto';

const GET_FUNDRAISER_AVATAR = gql`
  query GetFundraiserAvatar($id: String!) {
    findFundraisers(id: $id) {
      id
      avatarImage
      campaign {
        id
        fundraiserDefaultHeaderAvatarImage
      }
    }
  }
`;

const UPDATE_USER_AVATAR = gql`
  mutation UpdateUserAvatar($data: UserInput!) {
    updateUser(User: $data) {
      id
    }
  }
`;

const FundraiserAdminWelcomeAvatar = ({ progressCurrent, progressTotal, onContinue }) => {
  const { addToast } = useGlobal();
  const { user } = useAuth();
  const { fundraiserId } = useCampaignPage();

  const { data } = useQuery(GET_FUNDRAISER_AVATAR, { variables: { id: fundraiserId } });
  const fundraiser = useMemo(() => data?.findFundraisers[0], [data]);
  const campaign = useMemo(() => fundraiser?.campaign, [fundraiser]);

  const [updateUser, { loading }] = useMutation(UPDATE_USER_AVATAR);

  const handleAvatarUpload = async (newImage) => {
    try {
      await updateUser({
        variables: {
          data: {
            id: user.id,
            avatarImage: newImage,
          },
        },
        refetchQueries: ['GetFundraiserAvatar'],
      });

      onContinue();
    } catch (err) {
      if (config('/debug')) console.error(err);

      addToast({
        id: 'avatar-upload',
        type: 'error',
        duration: 'long',
        message: 'There was an error saving your profile photo. Please try again',
      });
    }
  };

  if (!fundraiser) return null;

  return (
    <div>
      <div className="text-center">
        <h1 className="pt-0.5 text-gray-800 font-medium text-2xl">Add profile photo</h1>
        <p className="mt-3 text-gray-700">
          Upload a profile photo so your friends can find you on the leaderboard.
        </p>
      </div>

      <div className="mt-4">
        <FormImageUploaderProfilePhoto
          defaultImage={
            campaign.fundraiserDefaultHeaderAvatarImage || config('/defaultFundraiserAvatar')
          }
          value={fundraiser.avatarImage}
          onChange={handleAvatarUpload}
        />
      </div>

      <div className="mt-8 flex justify-center">
        <ProgressIndicator current={progressCurrent} total={progressTotal} />
      </div>

      <div className="mt-8 grid grid-cols-1 gap-y-2">
        <FormImageUploaderButton
          value={fundraiser.avatarImage}
          buttonRadius="full"
          uploadLabel="Upload profile photo"
          continueLabel={loading ? 'Saving...' : 'Continue'}
          onChange={handleAvatarUpload}
          onContinue={onContinue}
        />
        <Button
          as="button"
          type="button"
          color="none"
          onClick={onContinue}
          className="font-medium w-full text-gray-600"
        >
          Skip
        </Button>
      </div>
    </div>
  );
};

FundraiserAdminWelcomeAvatar.propTypes = {
  progressCurrent: PropTypes.number.isRequired,
  progressTotal: PropTypes.number.isRequired,
  onContinue: PropTypes.func.isRequired,
};

FundraiserAdminWelcomeAvatar.defaultProps = {};

export default FundraiserAdminWelcomeAvatar;
