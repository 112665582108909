import { useMemo } from 'react';
import PropTypes from 'prop-types';
import { gql, useQuery } from '@apollo/client';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faUser,
  faCalendarStar,
  faBullseyeArrow,
  faPennant,
  faMoneyCheckDollarPen,
} from '@fortawesome/pro-regular-svg-icons';
import { faChevronRight } from '@fortawesome/pro-solid-svg-icons';

import useScreen from 'hooks/useScreen';
import { formatNumber, formatDateRange } from 'lib/formatters';
import { useCampaignPage } from 'context/CampaignPage';
import Tiles from 'components/common/Tiles';
import Link from 'components/common/Link';
import CampaignPageFundraiserAvatar from './CampaignPageFundraiserAvatar';
import CampaignPageFundraiserStatLine from './CampaignPageFundraiserStatLine';
import CaptainHat from '../../svg/icon-captain-hat.svg';

const GET_CAMPAIGN = gql`
  query GetCampaignPageIndividualInfo($campaignId: String!, $fundraiserWhere: SequelizeJSON!) {
    findCampaigns(id: $campaignId) {
      id
      isPerformanceEnabled
      fundraisers(where: $fundraiserWhere) {
        id
        myPermissions
        isTeamCaptain
        commitmentAmount
        performanceEstimate
        performanceSettings {
          metricLabelPlural
        }
        stats {
          aggregatedPerformanceResult
        }
        team {
          id
          name
        }
      }
      name
      performanceStartDate
      performanceEndDate
    }
  }
`;

const CampaignPageIndividualInfo = ({ className }) => {
  const screen = useScreen();
  const { fundraiserId, campaignId } = useCampaignPage();

  const { data } = useQuery(GET_CAMPAIGN, {
    variables: { campaignId, fundraiserWhere: { id: fundraiserId } },
  });
  const campaign = useMemo(() => data?.findCampaigns[0], [data]);
  const fundraiser = useMemo(() => campaign?.fundraisers[0], [campaign]);

  const metric = fundraiser?.performanceSettings?.metricLabelPlural;
  const hasPerformance = Boolean(metric) && campaign?.isPerformanceEnabled;

  const hasTeam = Boolean(fundraiser?.team);
  const teamDesignation = fundraiser?.isTeamCaptain ? 'Team Captain' : 'Member';

  const canManageCommitment =
    fundraiser?.commitmentAmount && fundraiser?.myPermissions?.includes('manageFundraiser');

  const performanceDateRange = useMemo(() => {
    if (!campaign?.isPerformanceEnabled) return null;

    return formatDateRange(campaign?.performanceStartDate, campaign?.performanceEndDate);
  }, [campaign]);

  if (!campaign) return null;

  return (
    <div className={className}>
      <div className="hidden lg:flex items-center mb-6">
        <CampaignPageFundraiserAvatar className="-ml-4 w-16 h-16" />
        <h2 className="text-xl leading-normal font-medium ml-6">Fundraiser Profile</h2>
      </div>
      <Tiles spacing="sm" columns={1}>
        {!screen.md && canManageCommitment && (
          <Link
            href={`/manage/f/${fundraiser.id}/commitment`}
            className="flex items-center justify-between text-gray-700 px-5 py-4 bg-gray-200 hover:bg-gray-300 rounded-xl"
          >
            <div className="flex items-center">
              <FontAwesomeIcon icon={faMoneyCheckDollarPen} size="lg" className="mr-5" />
              <p className="font-medium">View My Commitment</p>
            </div>
            <FontAwesomeIcon icon={faChevronRight} className="text-gray-500" />
          </Link>
        )}
        {hasPerformance && (
          <CampaignPageFundraiserStatLine
            icon={faBullseyeArrow}
            label={
              <>
                {formatNumber(fundraiser.stats.aggregatedPerformanceResult, '0,0.[00]')}/
                {formatNumber(fundraiser.performanceEstimate, '0,0')} {metric}
              </>
            }
          />
        )}
        <CampaignPageFundraiserStatLine
          icon={faCalendarStar}
          label={
            <>
              Participating in{' '}
              <Link
                href={`/c/${campaign.id}`}
                className="text-theme-secondary hover:text-theme-secondary-light transition-colors duration-200"
              >
                {campaign.name}
              </Link>
            </>
          }
        />
        {campaign.isPerformanceEnabled && (
          <CampaignPageFundraiserStatLine icon={faPennant} label={performanceDateRange} />
        )}
        {hasTeam && (
          <CampaignPageFundraiserStatLine
            icon={fundraiser.isTeamCaptain ? CaptainHat : faUser}
            label={
              <>
                {teamDesignation} of{' '}
                <Link
                  href={`/t/${fundraiser.team.id}`}
                  className="text-theme-secondary hover:text-theme-secondary-light transition-colors duration-200"
                >
                  {fundraiser.team.name}
                </Link>
              </>
            }
          />
        )}
        {screen.md && canManageCommitment && (
          <Link
            href={`/manage/f/${fundraiser.id}/commitment`}
            className="flex items-center justify-between text-gray-700 px-5 py-4 bg-gray-200 hover:bg-gray-300 -mx-4 rounded-xl"
          >
            <div className="flex items-center">
              <FontAwesomeIcon icon={faMoneyCheckDollarPen} size="lg" className="mr-5" />
              <p className="font-medium">View My Commitment</p>
            </div>
            <FontAwesomeIcon icon={faChevronRight} className="text-gray-500" />
          </Link>
        )}
      </Tiles>
    </div>
  );
};

CampaignPageIndividualInfo.propTypes = {
  className: PropTypes.string,
};

CampaignPageIndividualInfo.defaultProps = {
  className: '',
};

export default CampaignPageIndividualInfo;
